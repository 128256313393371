var render = function () {
  var _vm$editor, _vm$editor3, _vm$editor4, _vm$editor6, _vm$editor8, _vm$editor10, _vm$editor12, _vm$editor14, _vm$editor16, _vm$editor17, _vm$editor19, _vm$editor21, _vm$editor23, _vm$editor25, _vm$editor27, _vm$editor29, _vm$editor31, _vm$editor33, _vm$editor35, _vm$editor38, _vm$editor39, _vm$editor40, _vm$editor42, _vm$editor44, _vm$editor46, _vm$editor47, _vm$editor49, _vm$editor51, _vm$editor53, _vm$editor55, _vm$editor57, _vm$editor59, _vm$editor60, _vm$editor62, _vm$editor64, _vm$editor66, _vm$editor68, _vm$editor70, _vm$editor72, _vm$editor74, _vm$editor76, _vm$editor78, _vm$editor81, _vm$editor82, _vm$editor83, _vm$editor85;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['wysiwyg-editor', _vm.simple ? 'wysiwyg-editor--simple' : '', _vm.isClean ? 'wysiwyg-editor--clean' : '', _vm.readOnlyMode ? 'wysiwyg-editor--read' : ''],
    on: {
      "click": function click($event) {
        _vm.selectIsOpened = false;
      }
    }
  }, [_c('div', {
    staticClass: "wysiwyg-editor__inner"
  }, [!_vm.simple ? _c('div', {
    staticClass: "wysiwyg-editor__info"
  }, [_vm._t("additionalHeaderFields")], 2) : _vm._e(), _c('div', {
    staticClass: "wysiwyg-editor__body"
  }, [!_vm.simple && !_vm.readOnlyMode ? _c('div', {
    staticClass: "wysiwyg-editor__toolbar"
  }, [_vm.isClean ? _c('simplebar', {
    staticClass: "wysiwyg-editor__toolbar-wrapper",
    attrs: {
      "data-simplebar-auto-hide": "false"
    }
  }, [_c('div', {
    staticClass: "toolbar"
  }, [!_vm.isClean ? _c('div', {
    class: ['toolbar-select', _vm.selectIsOpened ? 'opened' : ''],
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.selectIsOpened = true;
      }
    }
  }, [_c('div', {
    staticClass: "toolbar-select__value"
  }, [_c('span', [_vm._v(_vm._s(_vm.currentTextType))]), _c('img', {
    staticClass: "toolbar-select__arrow",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })]), _c('div', {
    staticClass: "toolbar-select__dropdown",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.selectIsOpened = false;
      }
    }
  }, [_c('div', {
    class: ['toolbar-select__option', (_vm$editor = _vm.editor) !== null && _vm$editor !== void 0 && _vm$editor.isActive('paragraph') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor2;

        (_vm$editor2 = _vm.editor) === null || _vm$editor2 === void 0 ? void 0 : _vm$editor2.chain().focus().setParagraph().run();
      }
    }
  }, [_vm._v("Paragraph")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor3 = _vm.editor) !== null && _vm$editor3 !== void 0 && _vm$editor3.isActive('customTitleNode') ? 'selected' : ''],
    on: {
      "click": _vm.handleTitle
    }
  }, [_vm._v("Title")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor4 = _vm.editor) !== null && _vm$editor4 !== void 0 && _vm$editor4.isActive('heading', {
      level: 1
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor5;

        (_vm$editor5 = _vm.editor) === null || _vm$editor5 === void 0 ? void 0 : _vm$editor5.chain().focus().toggleHeading({
          level: 1
        }).run();
      }
    }
  }, [_vm._v("Heading 1")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor6 = _vm.editor) !== null && _vm$editor6 !== void 0 && _vm$editor6.isActive('heading', {
      level: 2
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor7;

        (_vm$editor7 = _vm.editor) === null || _vm$editor7 === void 0 ? void 0 : _vm$editor7.chain().focus().toggleHeading({
          level: 2
        }).run();
      }
    }
  }, [_vm._v("Heading 2")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor8 = _vm.editor) !== null && _vm$editor8 !== void 0 && _vm$editor8.isActive('heading', {
      level: 3
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor9;

        (_vm$editor9 = _vm.editor) === null || _vm$editor9 === void 0 ? void 0 : _vm$editor9.chain().focus().toggleHeading({
          level: 3
        }).run();
      }
    }
  }, [_vm._v("Heading 3")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor10 = _vm.editor) !== null && _vm$editor10 !== void 0 && _vm$editor10.isActive('heading', {
      level: 4
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor11;

        (_vm$editor11 = _vm.editor) === null || _vm$editor11 === void 0 ? void 0 : _vm$editor11.chain().focus().toggleHeading({
          level: 4
        }).run();
      }
    }
  }, [_vm._v("Heading 4")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor12 = _vm.editor) !== null && _vm$editor12 !== void 0 && _vm$editor12.isActive('heading', {
      level: 5
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor13;

        (_vm$editor13 = _vm.editor) === null || _vm$editor13 === void 0 ? void 0 : _vm$editor13.chain().focus().toggleHeading({
          level: 5
        }).run();
      }
    }
  }, [_vm._v("Heading 5")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor14 = _vm.editor) !== null && _vm$editor14 !== void 0 && _vm$editor14.isActive('heading', {
      level: 6
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor15;

        (_vm$editor15 = _vm.editor) === null || _vm$editor15 === void 0 ? void 0 : _vm$editor15.chain().focus().toggleHeading({
          level: 6
        }).run();
      }
    }
  }, [_vm._v("Heading 6")])])]) : _vm._e(), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', !((_vm$editor16 = _vm.editor) !== null && _vm$editor16 !== void 0 && _vm$editor16.can().undo()) || !_vm.hasChanges ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        return _vm.handleUndo();
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "23px",
      "height": "23px",
      "viewBox": "-2 0 23 23",
      "id": "meteor-icon-kit__regular-undo",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.41421 6L6.70711 8.29289C7.09763 8.68342 7.09763 9.31658 6.70711 9.70711C6.31658 10.0976 5.68342 10.0976 5.29289 9.70711L1.29289 5.70711C0.90237 5.31658 0.90237 4.68342 1.29289 4.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L4.41421 4H9.5C14.7467 4 19 8.25329 19 13.5C19 18.7467 14.7467 23 9.5 23C4.25329 23 0 18.7467 0 13.5C0 12.9477 0.44772 12.5 1 12.5C1.55228 12.5 2 12.9477 2 13.5C2 17.6421 5.35786 21 9.5 21C13.6421 21 17 17.6421 17 13.5C17 9.35786 13.6421 6 9.5 6H4.41421z",
      "fill": "currentColor"
    }
  })])])]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor17 = _vm.editor) === null || _vm$editor17 === void 0 ? void 0 : _vm$editor17.isActive('bold')
    },
    staticStyle: {
      "font-weight": "700"
    },
    on: {
      "click": function click($event) {
        var _vm$editor18;

        (_vm$editor18 = _vm.editor) === null || _vm$editor18 === void 0 ? void 0 : _vm$editor18.chain().focus().toggleBold().run();
      }
    }
  }, [_vm._v("B")]), _c('div', {
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor19 = _vm.editor) === null || _vm$editor19 === void 0 ? void 0 : _vm$editor19.isActive('italic')
    },
    staticStyle: {
      "font-style": "italic"
    },
    on: {
      "click": function click($event) {
        var _vm$editor20;

        (_vm$editor20 = _vm.editor) === null || _vm$editor20 === void 0 ? void 0 : _vm$editor20.chain().focus().toggleItalic().run();
      }
    }
  }, [_vm._v("i")]), _c('div', {
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor21 = _vm.editor) === null || _vm$editor21 === void 0 ? void 0 : _vm$editor21.isActive('underline')
    },
    staticStyle: {
      "text-decoration": "underline"
    },
    on: {
      "click": function click($event) {
        var _vm$editor22;

        (_vm$editor22 = _vm.editor) === null || _vm$editor22 === void 0 ? void 0 : _vm$editor22.chain().focus().toggleUnderline().run();
      }
    }
  }, [_vm._v("U")]), _c('div', {
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor23 = _vm.editor) === null || _vm$editor23 === void 0 ? void 0 : _vm$editor23.isActive('strike')
    },
    staticStyle: {
      "text-decoration": "line-through"
    },
    on: {
      "click": function click($event) {
        var _vm$editor24;

        (_vm$editor24 = _vm.editor) === null || _vm$editor24 === void 0 ? void 0 : _vm$editor24.chain().focus().toggleStrike().run();
      }
    }
  }, [_vm._v("S")])]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', (_vm$editor25 = _vm.editor) !== null && _vm$editor25 !== void 0 && _vm$editor25.isActive({
      textAlign: 'left'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor26;

        (_vm$editor26 = _vm.editor) === null || _vm$editor26 === void 0 ? void 0 : _vm$editor26.chain().focus().setTextAlign('left').run();
      }
    }
  }, [_vm._v("L")]), _c('div', {
    class: ['toolbar__btn', (_vm$editor27 = _vm.editor) !== null && _vm$editor27 !== void 0 && _vm$editor27.isActive({
      textAlign: 'center'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor28;

        (_vm$editor28 = _vm.editor) === null || _vm$editor28 === void 0 ? void 0 : _vm$editor28.chain().focus().setTextAlign('center').run();
      }
    }
  }, [_vm._v("C")]), _c('div', {
    class: ['toolbar__btn', (_vm$editor29 = _vm.editor) !== null && _vm$editor29 !== void 0 && _vm$editor29.isActive({
      textAlign: 'right'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor30;

        (_vm$editor30 = _vm.editor) === null || _vm$editor30 === void 0 ? void 0 : _vm$editor30.chain().focus().setTextAlign('right').run();
      }
    }
  }, [_vm._v("R")]), _c('div', {
    class: ['toolbar__btn', (_vm$editor31 = _vm.editor) !== null && _vm$editor31 !== void 0 && _vm$editor31.isActive({
      textAlign: 'justify'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor32;

        (_vm$editor32 = _vm.editor) === null || _vm$editor32 === void 0 ? void 0 : _vm$editor32.chain().focus().setTextAlign('justify').run();
      }
    }
  }, [_vm._v("J")])]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', (_vm$editor33 = _vm.editor) !== null && _vm$editor33 !== void 0 && _vm$editor33.isActive('bulletList') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor34;

        (_vm$editor34 = _vm.editor) === null || _vm$editor34 === void 0 ? void 0 : _vm$editor34.chain().focus().toggleBulletList().run();
      }
    }
  }, [_vm._v("UL")]), _c('div', {
    class: ['toolbar__btn', (_vm$editor35 = _vm.editor) !== null && _vm$editor35 !== void 0 && _vm$editor35.isActive('orderedList') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor36;

        (_vm$editor36 = _vm.editor) === null || _vm$editor36 === void 0 ? void 0 : _vm$editor36.chain().focus().toggleOrderedList().run();
      }
    }
  }, [_vm._v("OL")]), !_vm.isClean ? _c('div', {
    staticClass: "toolbar__btn",
    on: {
      "click": function click($event) {
        var _vm$editor37;

        (_vm$editor37 = _vm.editor) === null || _vm$editor37 === void 0 ? void 0 : _vm$editor37.chain().focus().insertTable({
          rows: 1,
          cols: 2,
          withHeaderRow: false
        }).run();
      }
    }
  }, [_vm._v("-|-")]) : _vm._e(), !_vm.isClean ? _c('div', {
    class: ['toolbar__btn', (_vm$editor38 = _vm.editor) !== null && _vm$editor38 !== void 0 && _vm$editor38.isActive('customTitleNode') ? 'selected' : ''],
    on: {
      "click": _vm.handleSection
    }
  }, [_vm._v("§")]) : _vm._e(), !_vm.isClean ? _c('div', {
    class: ['toolbar__btn', (_vm$editor39 = _vm.editor) !== null && _vm$editor39 !== void 0 && _vm$editor39.isActive('customPageDivider') ? 'selected' : ''],
    on: {
      "click": _vm.handlePageDivider
    }
  }, [_vm._v("--")]) : _vm._e()]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', !((_vm$editor40 = _vm.editor) !== null && _vm$editor40 !== void 0 && _vm$editor40.can().liftListItem('listItem')) ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor41;

        (_vm$editor41 = _vm.editor) === null || _vm$editor41 === void 0 ? void 0 : _vm$editor41.chain().focus().liftListItem('listItem').run();
      }
    }
  }, [_vm._v("<")]), _c('div', {
    class: ['toolbar__btn', !((_vm$editor42 = _vm.editor) !== null && _vm$editor42 !== void 0 && _vm$editor42.can().splitListItem('listItem')) ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor43;

        (_vm$editor43 = _vm.editor) === null || _vm$editor43 === void 0 ? void 0 : _vm$editor43.chain().focus().sinkListItem('listItem').run();
      }
    }
  }, [_vm._v(">")])]), _vm.isClean && _vm.supportImages ? _c('file-uploader', {
    attrs: {
      "supported-file-formats": _vm.supportedFileFormats,
      "type": "simple",
      "icon": "UPLOAD",
      "btnText": "",
      "tooltipText": "Upload image",
      "infoText": "",
      "inverted": true,
      "multiple": false
    },
    on: {
      "input": function input($event) {
        return _vm.addFile($event);
      }
    }
  }) : _vm._e()], 1)]) : _c('div', {
    staticClass: "toolbar"
  }, [!_vm.isClean ? _c('div', {
    class: ['toolbar-select', _vm.selectIsOpened ? 'opened' : ''],
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.selectIsOpened = true;
      }
    }
  }, [_c('div', {
    staticClass: "toolbar-select__value"
  }, [_c('span', [_vm._v(_vm._s(_vm.currentTextType))]), _c('img', {
    staticClass: "toolbar-select__arrow",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })]), _c('div', {
    staticClass: "toolbar-select__dropdown",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.selectIsOpened = false;
      }
    }
  }, [_c('div', {
    class: ['toolbar-select__option', (_vm$editor44 = _vm.editor) !== null && _vm$editor44 !== void 0 && _vm$editor44.isActive('paragraph') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor45;

        (_vm$editor45 = _vm.editor) === null || _vm$editor45 === void 0 ? void 0 : _vm$editor45.chain().focus().setParagraph().run();
      }
    }
  }, [_vm._v("Paragraph")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor46 = _vm.editor) !== null && _vm$editor46 !== void 0 && _vm$editor46.isActive('customTitleNode') ? 'selected' : ''],
    on: {
      "click": _vm.handleTitle
    }
  }, [_vm._v("Title")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor47 = _vm.editor) !== null && _vm$editor47 !== void 0 && _vm$editor47.isActive('heading', {
      level: 1
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor48;

        (_vm$editor48 = _vm.editor) === null || _vm$editor48 === void 0 ? void 0 : _vm$editor48.chain().focus().toggleHeading({
          level: 1
        }).run();
      }
    }
  }, [_vm._v("Heading 1")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor49 = _vm.editor) !== null && _vm$editor49 !== void 0 && _vm$editor49.isActive('heading', {
      level: 2
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor50;

        (_vm$editor50 = _vm.editor) === null || _vm$editor50 === void 0 ? void 0 : _vm$editor50.chain().focus().toggleHeading({
          level: 2
        }).run();
      }
    }
  }, [_vm._v("Heading 2")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor51 = _vm.editor) !== null && _vm$editor51 !== void 0 && _vm$editor51.isActive('heading', {
      level: 3
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor52;

        (_vm$editor52 = _vm.editor) === null || _vm$editor52 === void 0 ? void 0 : _vm$editor52.chain().focus().toggleHeading({
          level: 3
        }).run();
      }
    }
  }, [_vm._v("Heading 3")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor53 = _vm.editor) !== null && _vm$editor53 !== void 0 && _vm$editor53.isActive('heading', {
      level: 4
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor54;

        (_vm$editor54 = _vm.editor) === null || _vm$editor54 === void 0 ? void 0 : _vm$editor54.chain().focus().toggleHeading({
          level: 4
        }).run();
      }
    }
  }, [_vm._v("Heading 4")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor55 = _vm.editor) !== null && _vm$editor55 !== void 0 && _vm$editor55.isActive('heading', {
      level: 5
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor56;

        (_vm$editor56 = _vm.editor) === null || _vm$editor56 === void 0 ? void 0 : _vm$editor56.chain().focus().toggleHeading({
          level: 5
        }).run();
      }
    }
  }, [_vm._v("Heading 5")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor57 = _vm.editor) !== null && _vm$editor57 !== void 0 && _vm$editor57.isActive('heading', {
      level: 6
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor58;

        (_vm$editor58 = _vm.editor) === null || _vm$editor58 === void 0 ? void 0 : _vm$editor58.chain().focus().toggleHeading({
          level: 6
        }).run();
      }
    }
  }, [_vm._v("Heading 6")])])]) : _vm._e(), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', !((_vm$editor59 = _vm.editor) !== null && _vm$editor59 !== void 0 && _vm$editor59.can().undo()) || !_vm.hasChanges ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        return _vm.handleUndo();
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "23px",
      "height": "23px",
      "viewBox": "-2 0 23 23",
      "id": "meteor-icon-kit__regular-undo",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.41421 6L6.70711 8.29289C7.09763 8.68342 7.09763 9.31658 6.70711 9.70711C6.31658 10.0976 5.68342 10.0976 5.29289 9.70711L1.29289 5.70711C0.90237 5.31658 0.90237 4.68342 1.29289 4.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L4.41421 4H9.5C14.7467 4 19 8.25329 19 13.5C19 18.7467 14.7467 23 9.5 23C4.25329 23 0 18.7467 0 13.5C0 12.9477 0.44772 12.5 1 12.5C1.55228 12.5 2 12.9477 2 13.5C2 17.6421 5.35786 21 9.5 21C13.6421 21 17 17.6421 17 13.5C17 9.35786 13.6421 6 9.5 6H4.41421z",
      "fill": "currentColor"
    }
  })])])]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Bold',
      expression: "'Bold'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor60 = _vm.editor) === null || _vm$editor60 === void 0 ? void 0 : _vm$editor60.isActive('bold')
    },
    staticStyle: {
      "font-weight": "700"
    },
    on: {
      "click": function click($event) {
        var _vm$editor61;

        (_vm$editor61 = _vm.editor) === null || _vm$editor61 === void 0 ? void 0 : _vm$editor61.chain().focus().toggleBold().run();
      }
    }
  }, [_vm._v("B")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Italic',
      expression: "'Italic'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor62 = _vm.editor) === null || _vm$editor62 === void 0 ? void 0 : _vm$editor62.isActive('italic')
    },
    staticStyle: {
      "font-style": "italic"
    },
    on: {
      "click": function click($event) {
        var _vm$editor63;

        (_vm$editor63 = _vm.editor) === null || _vm$editor63 === void 0 ? void 0 : _vm$editor63.chain().focus().toggleItalic().run();
      }
    }
  }, [_vm._v("i")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Underline',
      expression: "'Underline'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor64 = _vm.editor) === null || _vm$editor64 === void 0 ? void 0 : _vm$editor64.isActive('underline')
    },
    staticStyle: {
      "text-decoration": "underline"
    },
    on: {
      "click": function click($event) {
        var _vm$editor65;

        (_vm$editor65 = _vm.editor) === null || _vm$editor65 === void 0 ? void 0 : _vm$editor65.chain().focus().toggleUnderline().run();
      }
    }
  }, [_vm._v("U")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Strike',
      expression: "'Strike'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor66 = _vm.editor) === null || _vm$editor66 === void 0 ? void 0 : _vm$editor66.isActive('strike')
    },
    staticStyle: {
      "text-decoration": "line-through"
    },
    on: {
      "click": function click($event) {
        var _vm$editor67;

        (_vm$editor67 = _vm.editor) === null || _vm$editor67 === void 0 ? void 0 : _vm$editor67.chain().focus().toggleStrike().run();
      }
    }
  }, [_vm._v("S")])]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Align left',
      expression: "'Align left'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor68 = _vm.editor) !== null && _vm$editor68 !== void 0 && _vm$editor68.isActive({
      textAlign: 'left'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor69;

        (_vm$editor69 = _vm.editor) === null || _vm$editor69 === void 0 ? void 0 : _vm$editor69.chain().focus().setTextAlign('left').run();
      }
    }
  }, [_vm._v("L")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Align center',
      expression: "'Align center'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor70 = _vm.editor) !== null && _vm$editor70 !== void 0 && _vm$editor70.isActive({
      textAlign: 'center'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor71;

        (_vm$editor71 = _vm.editor) === null || _vm$editor71 === void 0 ? void 0 : _vm$editor71.chain().focus().setTextAlign('center').run();
      }
    }
  }, [_vm._v("C")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Align right',
      expression: "'Align right'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor72 = _vm.editor) !== null && _vm$editor72 !== void 0 && _vm$editor72.isActive({
      textAlign: 'right'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor73;

        (_vm$editor73 = _vm.editor) === null || _vm$editor73 === void 0 ? void 0 : _vm$editor73.chain().focus().setTextAlign('right').run();
      }
    }
  }, [_vm._v("R")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Justify',
      expression: "'Justify'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor74 = _vm.editor) !== null && _vm$editor74 !== void 0 && _vm$editor74.isActive({
      textAlign: 'justify'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor75;

        (_vm$editor75 = _vm.editor) === null || _vm$editor75 === void 0 ? void 0 : _vm$editor75.chain().focus().setTextAlign('justify').run();
      }
    }
  }, [_vm._v("J")])]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Unordered list',
      expression: "'Unordered list'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor76 = _vm.editor) !== null && _vm$editor76 !== void 0 && _vm$editor76.isActive('bulletList') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor77;

        (_vm$editor77 = _vm.editor) === null || _vm$editor77 === void 0 ? void 0 : _vm$editor77.chain().focus().toggleBulletList().run();
      }
    }
  }, [_vm._v("UL")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Ordered list',
      expression: "'Ordered list'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor78 = _vm.editor) !== null && _vm$editor78 !== void 0 && _vm$editor78.isActive('orderedList') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor79;

        (_vm$editor79 = _vm.editor) === null || _vm$editor79 === void 0 ? void 0 : _vm$editor79.chain().focus().toggleOrderedList().run();
      }
    }
  }, [_vm._v("OL")]), !_vm.isClean ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Split text',
      expression: "'Split text'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    on: {
      "click": function click($event) {
        var _vm$editor80;

        (_vm$editor80 = _vm.editor) === null || _vm$editor80 === void 0 ? void 0 : _vm$editor80.chain().focus().insertTable({
          rows: 1,
          cols: 2,
          withHeaderRow: false
        }).run();
      }
    }
  }, [_vm._v("-|-")]) : _vm._e(), !_vm.isClean ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Add paragraph',
      expression: "'Add paragraph'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor81 = _vm.editor) !== null && _vm$editor81 !== void 0 && _vm$editor81.isActive('customSectionNode') ? 'selected' : ''],
    on: {
      "click": _vm.handleSection
    }
  }, [_vm._v("§")]) : _vm._e(), !_vm.isClean ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Add page break',
      expression: "'Add page break'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor82 = _vm.editor) !== null && _vm$editor82 !== void 0 && _vm$editor82.isActive('customPageDivider') ? 'selected' : ''],
    on: {
      "click": _vm.handlePageDivider
    }
  }, [_vm._v("--")]) : _vm._e()]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Indent left',
      expression: "'Indent left'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', !((_vm$editor83 = _vm.editor) !== null && _vm$editor83 !== void 0 && _vm$editor83.can().liftListItem('listItem')) ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor84;

        (_vm$editor84 = _vm.editor) === null || _vm$editor84 === void 0 ? void 0 : _vm$editor84.chain().focus().liftListItem('listItem').run();
      }
    }
  }, [_vm._v("<")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Indent right',
      expression: "'Indent right'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', !((_vm$editor85 = _vm.editor) !== null && _vm$editor85 !== void 0 && _vm$editor85.can().splitListItem('listItem')) ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor86;

        (_vm$editor86 = _vm.editor) === null || _vm$editor86 === void 0 ? void 0 : _vm$editor86.chain().focus().sinkListItem('listItem').run();
      }
    }
  }, [_vm._v(">")])])])], 1) : _vm._e(), _c('div', {
    staticClass: "wysiwyg-editor__content"
  }, [_c('editor-content', {
    staticClass: "tiptap",
    attrs: {
      "editor": _vm.editor
    }
  })], 1), _c('div', {
    staticClass: "wysiwyg-editor__footer"
  }, [_vm._t("footer")], 2)]), !_vm.simple && !_vm.isClean ? _c('div', {
    staticClass: "wysiwyg-editor__sidebar"
  }, [_vm._t("sidebar")], 2) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }