










































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import FileUploader from '@/components/FileUploader.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import CustomParagraphNode from '../wysiwyg/CustomParagraphNode';
import CustomTitleNode from '../wysiwyg/CustomTitleNode';
import CustomPageDivider from './CustomPageDivider';
import CustomMention from '../wysiwyg/CustomMention';
import { Editor, EditorContent } from '@tiptap/vue-2';
// import { Watch } from 'vue-property-decorator';
import BulletList from '@tiptap/extension-bullet-list';
import CustomListItem from '../wysiwyg/CustomListItem';
import OrderedList from '@tiptap/extension-ordered-list';
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Underline from '@tiptap/extension-underline';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import {
  ProductSummaryInvestor,
  ProductSummaryIssuer
} from "@/modules/genprox/modules/fund/modules/capital-rise/models/ProductSummaryData.interface";
import { Product } from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";

import suggestion from '../wysiwyg/Suggestion';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import History from '@tiptap/extension-history';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { create, all } from 'mathjs'
import { SeriesOfSharesObject } from '../../modules/fund/modules/capital-rise/modules/subscriptions/store/types';
const math = create(all);
const n2words = require('@/scripts/n2words.min.js')

@Component({
  components: { EditorContent, FileUploader, SygniRectButton, SygniRoundedButton, SygniInput, SygniFileBox, SygniContainerTitle, simplebar },
})
export default class EditorComponent extends Vue {
  readonly supportedFileFormats: Array<string> = ['jpg', 'jpeg', 'png'];
  selectIsOpened: boolean = false;
  editor: any = null;
  initialContent: string = '';

  @Prop({default: false}) editable: boolean;
  @Prop({default: false}) simple: boolean;
  @Prop() content: string;
  @Prop() issuer: ProductSummaryIssuer = null;
  @Prop() investor: ProductSummaryInvestor = null;
  @Prop() product: Product | any = { } as Product;
  @Prop() subscription: any = null;
  @Prop() campaign: any = null;
  @Prop() legalEntityData: any = null;
  @Prop() legalEntityInvestorData: any = null;
  @Prop() investorDetails: any = null;
  @Prop({default: false}) readOnlyMode: boolean;
  @Prop({default: true}) supportImages: boolean;
  @Prop({ default: 'default' }) layout: 'default' | 'clean';

  get isClean() {
    return this.layout === 'clean' ? true : false;
  }

  get currentTextType() {
    if (this.editor?.isActive('customTitleNode')) {
      return 'Title'
    }

    return this.editorHeadingLevel ? `Heading ${this.editorHeadingLevel}` : 'Paragraph'
  }

  get editorHeadingLevel() {
    return this.editor?.getAttributes('heading') ? this.editor?.getAttributes('heading')?.level : false;
  }

  get productDataName() {
    return 'data';
    // return this.product?.type === 'LOAN' ? 'loanData' : 'billAgreementData';
  }

  get editorBody() {
    const div = document.createElement('div');
    const body = this.content;
    div.innerHTML = body;

    if(this.simple === true) {
      div.innerHTML = this.applyValues(div);
    }

    return div.innerHTML;
  }

  get hasChanges() {
    const editorBody = this.getEditorBody();
    return this.initialContent != editorBody;
  }

  async addFile(files: File[]) {
    const blob = await fetch(URL.createObjectURL(files[0])).then(res => res.blob());

    const result = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    this.editor?.commands?.setImage({ src: result })
    this.$emit('blur', this.getEditorBody());
  }

  handleUndo() {
    if (!(!this.editor?.can().undo() || !this.hasChanges)) {
      this.editor?.chain().focus().undo().run();
    }
  }

  dateFormat(date: any) {
    if (date === undefined) return '';
    return moment(date).format("DD-MM-YYYY")
  }

  applyValues(div: HTMLElement): string {
    const tags = Array.from(div.querySelectorAll('.mention'));

    if(tags.length) {
      tags.forEach((tag: any) => {
        tag.textContent = this.getValue(tag.textContent);
        tag.removeAttribute('data-type');
        tag.removeAttribute('data-id');
      });
    }

    return div.innerHTML;
  }

  getValue(key: string) {
    let value: string = key;

    if(!(this.$route.name == 'template' || this.$route.name == 'template-preview')) {
      const objectKey = this.product?.type === 'JOINT_STOCK_SHARES' ? 'seriesOfShares' : 'llcShares';
      const increasedShareCapital = math.number(math.add(math.bignumber(this.legalEntityData?.shareCapital || 0), math.bignumber(this.subscription?.capitalValue || 0)))
      const annexItems = this.product?.annexSourceProducts;

      switch (key) {
        // campaign fields
        case '@Campaign Name':
          value = this.campaign?.campaignName || '';
          break;
        case '@Campaign Investment Value':
          value = this.campaign?.investmentValue ? `${this.$options.filters.numberFormat(this.campaign.investmentValue, 2)} PLN` : '';
          break;
        case '@Campaign Investment Value in English':
          value = this.campaign?.investmentValue ? n2words(this.campaign.investmentValue, { lang: 'en' }) : '';
          break;
        case '@Campaign Investment Value in Polish':
          value = this.campaign?.investmentValue ? n2words(this.campaign.investmentValue, { lang: 'pl' }) : '';
          break;
        // product fields
        case '@Product Code':
          value = this.product?.code ? this.product.code : '';
          break;
        case '@Product Name':
          value = this.product?.name ? this.product.name : '';
          break;
        case '@Product Type':
          value = this.product?.type ? this.product.type : '';
          break;
        case '@Agreement Date':
        case '@Product Agreement Date':
          value = this.product?.agreementDate ? this.dateFormat(this.product.agreementDate) : '';
          break;
        case '@Investment Value':
        case '@Product Investment Value':
          value = this.product?.investmentValue ? `${this.$options.filters.numberFormat(this.product.investmentValue, 2)} ${this.product.currency}` : '';
          break;
        case '@Investment Value in English':
          value = this.product?.investmentValue ? n2words(this.product.investmentValue, { lang: 'en' }) : '';
          break;
        case '@Investment Value in Polish':
          value = this.product?.investmentValue ? n2words(this.product.investmentValue, { lang: 'pl' }) : '';
          break;
        case '@Fixed Repayment Day':
          value = this.product[this.productDataName]?.fixedRepaymentDate ? this.dateFormat(this.product[this.productDataName].fixedRepaymentDate) : '';
          break;
        case '@End Date':
          value = this.product?.capitalRepaymentDate ? this.dateFormat(this.product.capitalRepaymentDate) : '';
          break;
        case '@Enforcement Clause Date':
          value = this.product?.enforcementClauseDate ? this.dateFormat(this.product.enforcementClauseDate) : '';
          break;
        case '@Duration':
          value = this.product[this.productDataName]?.durationType ? this.product[this.productDataName].durationType : '';

          if (!value) {
            value = this.product[this.productDataName]?.duration?.type ? this.product[this.productDataName]?.duration?.type : '';
          }

          break;
        case '@Duration Count':
          value = this.product[this.productDataName]?.durationCount ? this.product[this.productDataName].durationCount : '';

          if (!value) {
            value = this.product[this.productDataName]?.duration?.count ? this.product[this.productDataName]?.duration?.count : '';
          }

          break;
        case '@Interest Type':
          value = this.product[this.productDataName]?.interestType ? this.product[this.productDataName].interestType : '';
          break;
        case '@Interest P.A.':
          if (this.product[this.productDataName]?.simpleOptions?.interest) {
            value = `${this.product[this.productDataName].simpleOptions.interest}%`
          } else {
            value = `${Number(this.product[this.productDataName]?.advancedOptions?.commission || 0) + Number(this.product[this.productDataName]?.advancedOptions?.margin || 0)}%` 
          }
          break;
        case '@Interest P.A. in English':
          if (this.product[this.productDataName]?.simpleOptions?.interest) {
            value = n2words(this.product[this.productDataName].simpleOptions.interest, { lang: 'en' })
          } else {
            value = n2words(Number(this.product[this.productDataName]?.advancedOptions?.commission || 0) + Number(this.product[this.productDataName]?.advancedOptions?.margin || 0), { lang: 'en' })
          }
          break;
        case '@Interest P.A. in Polish':
          if (this.product[this.productDataName]?.simpleOptions?.interest) {
            value = n2words(this.product[this.productDataName].simpleOptions.interest, { lang: 'pl' })
          } else {
            value = n2words(Number(this.product[this.productDataName]?.advancedOptions?.commission || 0) + Number(this.product[this.productDataName]?.advancedOptions?.margin || 0), { lang: 'pl' })
          }
          break;
        case '@Interest Payment':
          // eslint-disable-next-line no-case-declarations
          const optionValue = this.product[this.productDataName]?.simpleOptions?.interestPayment ? this.product[this.productDataName]?.simpleOptions?.interestPayment : this.product[this.productDataName]?.advancedOptions?.commissionPayment;

          switch(optionValue?.toLowerCase()) {
            case('monthly'):
              value = 'co miesiąc';
              break;
            case('quarterly'):
              value = 'co kwartał';
              break;
            case('semi-annually'):
              value = 'co pół roku';
              break;
            case('annually'):
              value = 'co rok';
              break;
            case('at maturity'):
              value = 'w dacie zapadalności';
              break;
          }
          break;
        case '@Commission P.A.':
          value = this.product[this.productDataName]?.advancedOptions?.commission ? `${this.product[this.productDataName].advancedOptions.commission}%` : '';
          break;
        case '@Commission Type':
          value = this.product[this.productDataName]?.advancedOptions?.commissionType ? this.product[this.productDataName].advancedOptions.commissionType : '';
          break;
        case '@Margin P.A.':
          value = this.product[this.productDataName]?.advancedOptions?.margin ? `${this.product[this.productDataName].advancedOptions.margin}%` : '';
          break;
        case '@Commission Payment':
          value = this.product[this.productDataName]?.advancedOptions?.commissionPayment ? this.product[this.productDataName].advancedOptions.commissionPayment : '';
          break;
        case '@Margin Payment':
          value = this.product[this.productDataName]?.advancedOptions?.marginPayment ? this.product[this.productDataName].advancedOptions.marginPayment : '';
          break;
        case '@Interest Calculation Method':
          value = this.product[this.productDataName]?.interestCalculationMethod ? this.product[this.productDataName].interestCalculationMethod : '';
          break;
        case '@Interest Paid Within':
          value = this.product[this.productDataName]?.interestPaidWithin ? this.product[this.productDataName].interestPaidWithin : '';
          break;
        case '@Initial Payment':
          value = this.product[this.productDataName]?.initialPaymentBankAccount?.account ? this.$options.filters.bankAccountNumber(this.product[this.productDataName].initialPaymentBankAccount.account) : '';
          break;
        case '@Initial Payment Bank Name':
          value = this.product[this.productDataName]?.initialPaymentBankAccount?.name ? this.product[this.productDataName].initialPaymentBankAccount.name : '';
          break;
        case '@Principal Repayment':
          value = this.product[this.productDataName]?.principalPaymentBankAccount?.account ? this.$options.filters.bankAccountNumber(this.product[this.productDataName].principalPaymentBankAccount.account) : '';
          break;
        case '@Principal Repayment Bank Name':
          value = this.product[this.productDataName]?.principalPaymentBankAccount?.name ? this.product[this.productDataName].principalPaymentBankAccount.name : '';
          break;
        case '@Interest Repayment':
          value = this.product[this.productDataName]?.interestPaymentBankAccount?.account ? this.$options.filters.bankAccountNumber(this.product[this.productDataName].interestPaymentBankAccount.account) : '';
          break;
        case '@Interest Repayment Bank Name':
          value = this.product[this.productDataName]?.interestPaymentBankAccount?.name ? this.product[this.productDataName].interestPaymentBankAccount.name : '';
          break;
        case '@Debtor':
          value = this.product[this.productDataName]?.debtor?.account ? this.$options.filters.bankAccountNumber(this.product[this.productDataName].debtor.account) : '';
          break;
        case '@Debtor Bank Name':
          value = this.product[this.productDataName]?.debtor?.name ? this.product[this.productDataName].debtor.name : '';
          break;
        case '@Bill Number':
          value = this.product[this.productDataName]?.billNumber ? this.product[this.productDataName].billNumber : '';
          break;
        case '@Currency Name & Symbol':
          value = ''
          if (this.product?.currency === 'PLN') {
            value = 'walucie polskiej (PLN)'
          } else if (this.product?.currency === 'EUR') {
            value = 'walucie euro (EUR)'
          } else if (this.product?.currency === 'USD') {
            value = 'dolarach amerykańskich (USD)'
          }
          break;
        case '@Currency Name':
          value = ''
          if (this.product?.currency === 'PLN') {
            value = 'złotych'
          } else if (this.product?.currency === 'EUR') {
            value = 'euro'
          } else if (this.product?.currency === 'USD') {
            value = 'dolarów amerykańskich'
          }
          break;
        case '@Annexed Product Codes':
          value = ''
          if (annexItems?.length) {
            value = annexItems?.map((el: any) => el.code)?.join(', ')
          }

          break;
        case '@Annexed Agreement Dates':
          value = ''
          if (annexItems?.length) {
            value = annexItems?.map((el: any) => this.dateFormat(el.agreementDate))?.join(', ')
          }
          break;
        case '@Annexed End Dates':
          value = ''
          if (annexItems?.length) {
            value = annexItems?.map((el: any) => this.dateFormat(el.endDate))?.join(', ')
          }
          break;
        case '@Annexed Investment Values':
          value = ''
          if (annexItems?.length) {
            value = annexItems?.map((el: any) => this.$options.filters.numberFormat(el.investmentValue || 0, 2))?.join(', ')
          }
          break;
        // series of shares fields
        case '@Series Counter From':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.counterFrom ? this.$options.filters.thousandSeparator(this.product.data[objectKey].counterFrom) : ''
          }
          break;
        case '@Series Counter From in English':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.counterFrom ? n2words(this.product.data[objectKey].counterFrom, { lang: 'en' }) : ''
          }
          break;
        case '@Series Counter From in Polish':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.counterFrom ? n2words(this.product.data[objectKey].counterFrom, { lang: 'pl' }) : ''
          }
          break;
        case '@Series Counter To':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.counterTo ? this.$options.filters.thousandSeparator(this.product.data[objectKey].counterTo) : ''
          }
          break;
        case '@Series Counter To in English':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.counterTo ? n2words(this.product.data[objectKey].counterTo, { lang: 'en' }) : ''
          }
          break;
        case '@Series Counter To in Polish':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.counterTo ? n2words(this.product.data[objectKey].counterTo, { lang: 'pl' }) : ''
          }
          break;
        case '@Series Instrument Count':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.instrumentCount ? this.$options.filters.thousandSeparator(this.product.data[objectKey].instrumentCount) : ''
          }
          break;
        case '@Series Instrument Count in English':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.instrumentCount ? n2words(this.product.data[objectKey].instrumentCount, { lang: 'en' }) : ''
          }
          break;
        case '@Series Instrument Count in Polish':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.instrumentCount ? n2words(this.product.data[objectKey].instrumentCount, { lang: 'pl' }) : ''
          }
          break;
        case '@Series Serial Number':
          value = this.product?.data[objectKey]?.name ? this.product?.data[objectKey]?.name : '';
          break;
        case '@Series Nominal Value':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.nominalValue ? this.$options.filters.numberFormat(this.product.data[objectKey].nominalValue, 2) : ''
          }
          break;
        case '@Series Nominal Value in English':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.nominalValue ? n2words(this.product.data[objectKey].nominalValue, { lang: 'en' }) : ''
          }
          break;
        case '@Series Nominal Value in Polish':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.nominalValue ? n2words(this.product.data[objectKey].nominalValue, { lang: 'pl' }) : ''
          }
          break;
        case '@Series Issue Value':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.issueValue ? this.$options.filters.numberFormat(this.product.data[objectKey].issueValue, 2) : ''
          }
          break;
        case '@Series Issue Value in English':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.issueValue ? n2words(this.product.data[objectKey].issueValue, { lang: 'en' }) : ''
          }
          break;
        case '@Series Issue Value in Polish':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.product?.data[objectKey]?.issueValue ? n2words(this.product.data[objectKey].issueValue, { lang: 'pl' }) : ''
          }
          break;
        case '@Series Investment Value':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.$options.filters.numberFormat(math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.issueValue))), 2)
          }
          break;
        case '@Series Investment Value in English':
          value = ''
          if (this.product?.data[objectKey]) {
            value = n2words(math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.issueValue))), { lang: 'en' })
          }
          break;
        case '@Series Investment Value in Polish':
          value = ''
          if (this.product?.data[objectKey]) {
            value = n2words(math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.issueValue))), { lang: 'pl' })
          }
          break;
        case '@Series Capital Value':
          value = ''
          if (this.product?.data[objectKey]) {
            value = this.$options.filters.numberFormat(math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.nominalValue))), 2)
          }
          break;
        case '@Series Capital Value in English':
          value = ''
          if (this.product?.data[objectKey]) {
            value = n2words(math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.nominalValue))), { lang: 'en' })
          }
          break;
        case '@Series Capital Value in Polish':
          value = ''
          if (this.product?.data[objectKey]) {
            value = n2words(math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.nominalValue))), { lang: 'pl' })
          }
          break;
        // advisor fields
        case '@Advisor Name':
          value = this.product?.broker?.firstName || this.product?.broker?.surname ? `${this.product?.broker?.firstName ? this.product.broker.firstName : ''} ${this.product?.broker?.surname ? this.product.broker.surname : ''}` : '';
          break;
        case '@Advisor Email':
          value = this.product?.broker?.email ? this.product.broker.email : '';
          break;
        case '@Advisor Identification No.':
          value = this.product?.broker?.identificationNumber ? this.product.broker.identificationNumber : '';
          break;
        case '@Advisor Document Type':
          value = this.product?.broker?.documentType ? this.product.broker.documentType : '';
          break;
        case '@Advisor Document Number':
          value = this.product?.broker?.documentNumber ? this.product.broker.documentNumber : '';
          break;
        case '@Advisor Document Country':
          value = this.product?.broker?.documentCountry ? this.product.broker.documentCountry : '';
          break;
        case '@Advisor Document Expiry Date':
          if (this.product?.broker?.documentExpiryDate?.expiryDate || this.product?.broker?.documentExpiryDate?.indefinitely) {
            value = this.product?.broker?.documentExpiryDate?.indefinitely ? '-' : this.product?.broker?.documentExpiryDate?.expiryDate ? this.dateFormat(this.product.broker.documentExpiryDate.expiryDate) : ''
          } else {
            value = this.product?.broker?.documentExpiryDate ? this.dateFormat(this.product.broker.documentExpiryDate) : '';
          }
          break;
        case '@Advisor Start Power of Attorney':
          value = this.product?.broker?.startPowerOfAttorney ? this.dateFormat(this.product.broker.startPowerOfAttorney) : '';
          break;
        case '@Advisor End Power of Attorney':
          value = this.product?.broker?.endPowerOfAttorney ? this.dateFormat(this.product.broker.endPowerOfAttorney) : '';
          break;
        // investor fields
        case '@Investor Name':
          value = this.investor?.name ? this.investor.name : '';
          break;
        case '@Investor First Name':
          value = this.investorDetails?.generalData?.firstName ? this.investorDetails?.generalData.firstName : '';
          break;
        case '@Investor Surname':
          value = this.investorDetails?.generalData?.surname ? this.investorDetails?.generalData.surname : '';
          break;
        case '@Investor Street':
        case '@Investor Street (residential address)':
          value = this.investorDetails?.residentialAddress?.street ? this.investorDetails.residentialAddress.street : '';
          break;
        case '@Investor Street (mailing address)':
          value = this.investorDetails?.mailingAddress?.street ? this.investorDetails.mailingAddress.street : '';
          break;
        case '@Investor House Number':
        case '@Investor House Number (residential address)':
          value = this.investorDetails?.residentialAddress?.houseNumber ? this.investorDetails.residentialAddress.houseNumber : '';
          break;
        case '@Investor House Number (mailing address)':
          value = this.investorDetails?.mailingAddress?.houseNumber ? this.investorDetails.mailingAddress.houseNumber : '';
          break;
        case '@Investor Flat Number':
        case '@Investor Flat Number (residential address)':
          value = this.investorDetails?.residentialAddress?.flatNumber ? this.investorDetails.residentialAddress.flatNumber : '';
          break;
        case '@Investor Flat Number (mailing address)':
          value = this.investorDetails?.mailingAddress?.flatNumber ? this.investorDetails.mailingAddress.flatNumber : '';
          break;
        case '@Investor City':
        case '@Investor City (residential address)':
          value = this.investorDetails?.residentialAddress?.city ? this.investorDetails.residentialAddress.city : '';
          break;
        case '@Investor City (mailing address)':
          value = this.investorDetails?.mailingAddress?.city ? this.investorDetails.mailingAddress.city : '';
          break;
        case '@Investor Zip Code':
        case '@Investor Zip Code (residential address)':
          value = this.investorDetails?.residentialAddress?.zipCode ? this.investorDetails.residentialAddress.zipCode : '';
          break;
        case '@Investor Zip Code (mailing address)':
          value = this.investorDetails?.mailingAddress?.zipCode ? this.investorDetails.mailingAddress.zipCode : '';
          break;
        case '@Investor Country':
        case '@Investor Country (residential address)':
          value = this.investorDetails?.residentialAddress?.country ? this.investorDetails.residentialAddress.country : '';
          break;
        case '@Investor Country (mailing address)':
          value = this.investorDetails?.mailingAddress?.country ? this.investorDetails.mailingAddress.country : '';
          break;
        case '@Investor Identification No.':
          value = this.investorDetails?.details?.identificationNumber ? this.investorDetails.details.identificationNumber : '';
          break;
        case '@Investor Document Type':
          value = this.investorDetails?.details?.documentType ? this.investorDetails.details.documentType : '';
          break;
        case '@Investor Document Type in Polish':
          value = this.investorDetails?.details?.documentType ? this.investorDetails.details.documentType : '';

          if (value?.toUpperCase() === 'ID_CARD') {
            value = 'dowodem osobistym'
          }

          if (value?.toUpperCase() === 'PASSPORT') {
            value = 'paszportem'
          }

          break;
        case '@Investor Document Number':
          value = this.investorDetails?.details?.documentNumber ? this.investorDetails.details.documentNumber : '';
          break;
        case '@Investor Document Country':
          value = this.investorDetails?.details?.documentCountry ? this.investorDetails.details.documentCountry : '';
          break;
        case '@Investor Document Expiry Date':
          if (this.investorDetails?.details?.documentExpiryDate?.expiryDate || this.investorDetails?.details?.documentExpiryDate?.indefinitely) {
            value = this.investorDetails?.details?.indefinitely ? '-' : this.investorDetails?.details?.documentExpiryDate?.expiryDate ? this.dateFormat(this.investorDetails.details.documentExpiryDate.expiryDate) : '';
          } else {
            value = this.investorDetails?.details?.documentExpiryDate ? this.dateFormat(this.investorDetails.details.documentExpiryDate) : '';
          }
          break;
        case '@Investor Country of Tax Residence':
          value = this.investorDetails?.details?.country ? this.investorDetails.details.country : '';
          break;
        case '@Investor Middle Name':
          value = this.investorDetails?.details?.middleName ? this.investorDetails.details.middleName : '';
          break;
        case '@Investor Birthday':
          value = this.investorDetails?.details?.birthDate ? this.dateFormat(this.investorDetails.details.birthDate) : '';
          break;
        case '@Investor Birth Place':
          value = this.investorDetails?.details?.birthPlace ? this.investorDetails.details.birthPlace : '';
          break;
        case '@Investor Nationality':
          value = this.investorDetails?.details?.nationality ? this.investorDetails.details.nationality : '';
          break;
        case '@Investor Fathers Name':
          value = this.investorDetails?.details?.fathersName ? this.investorDetails.details.fathersName : '';
          break;
        case '@Investor Mothers Name':
          value = this.investorDetails?.details?.mothersName ? this.investorDetails.details.mothersName : '';
          break;
        case '@Investor Phone No.':
          value = this.investorDetails?.generalData?.phone ? this.investorDetails.generalData.phone : '';
          break;
        case '@Investor Email':
          value = this.investorDetails?.generalData?.email ? this.investorDetails.generalData.email : '';
          break;
        // issuer fields
        case '@Issuer Name':
          value = this.issuer?.name ? this.issuer.name : '';
          break;
        case '@Issuer Street':
          value = this.issuer?.address?.street ? this.issuer.address.street : '';
          break;
        case '@Issuer House Number':
          value = this.issuer?.address?.houseNumber ? this.issuer.address.houseNumber : '';
          break;
        case '@Issuer Flat Number':
          value = this.issuer?.address?.flatNumber ? this.issuer.address.flatNumber : '';
          break;
        case '@Issuer City':
          value = this.issuer?.address?.city ? this.issuer.address.city : '';
          break;
        case '@Issuer Zip Code':
          value = this.issuer?.address?.zipCode ? this.issuer.address.zipCode : '';
          break;
        case '@Issuer Country':
          value = this.issuer?.address?.country ? this.issuer.address.country : '';
          break;
        case '@Issuer Register Number':
          value = this.issuer?.registerNumber ? this.issuer.registerNumber : '';
          break;
        case '@Issuer Tax Number':
          value = this.issuer?.taxNumber ? this.issuer.taxNumber : '';
          break;
        case '@Issuer Statistical Number':
          value = this.issuer?.statisticalNumber ? this.issuer.statisticalNumber : '';
          break;
        // subscription fields
        case '@Subscription Investment Value':
          value = this.subscription?.investmentValue ? `${this.$options.filters.numberFormat(this.subscription.investmentValue, 2)} ${this.subscription.currency}` : '';
          break;
        case '@Subscription Investment Value in English':
          value = this.subscription?.investmentValue ? n2words(this.subscription.investmentValue, { lang: 'en' }) : '';
          break;
        case '@Subscription Investment Value in Polish':
          value = this.subscription?.investmentValue ? n2words(this.subscription.investmentValue, { lang: 'pl' }) : '';
          break;
        case '@Subscription Agreement Date':
          value = this.subscription?.agreementDate ? this.dateFormat(this.subscription.agreementDate) : '';
          break;
        case '@Subscription Code':
          value = this.subscription?.code ? this.subscription.code : '';
          break;
        case '@Share Capital':
          value = this.legalEntityData?.shareCapital ? this.$options.filters.numberFormat(this.legalEntityData.shareCapital, 2) : '';
          value = this.legalEntityData?.shareCapital ? this.$options.filters.numberFormat(this.legalEntityData.shareCapital, 2) : '';
          value = this.legalEntityData?.shareCapital ? this.$options.filters.numberFormat(this.legalEntityData.shareCapital, 2) : '';
          break;
        case '@Share Capital in English':
          value = this.legalEntityData?.shareCapital ? n2words(this.legalEntityData.shareCapital, { lang: 'en' }) : '';
          value = this.legalEntityData?.shareCapital ? n2words(this.legalEntityData.shareCapital, { lang: 'en' }) : '';
          value = this.legalEntityData?.shareCapital ? n2words(this.legalEntityData.shareCapital, { lang: 'en' }) : '';
          break;
        case '@Share Capital in Polish':
          value = this.legalEntityData?.shareCapital ? n2words(this.legalEntityData.shareCapital, { lang: 'pl' }) : '';
          value = this.legalEntityData?.shareCapital ? n2words(this.legalEntityData.shareCapital, { lang: 'pl' }) : '';
          value = this.legalEntityData?.shareCapital ? n2words(this.legalEntityData.shareCapital, { lang: 'pl' }) : '';
          break;
        case '@Capital Value':
          value = this.subscription?.capitalValue ? this.$options.filters.numberFormat(this.subscription.capitalValue, 2) : '';
          break;
        case '@Capital Value in English':
          value = this.subscription?.capitalValue ? n2words(this.subscription.capitalValue, { lang: 'en' }) : '';
          break;
        case '@Capital Value in Polish':
          value = this.subscription?.capitalValue ? n2words(this.subscription.capitalValue, { lang: 'pl' }) : '';
          break;
        case '@Increased Share Capital':
          value = this.legalEntityData?.shareCapital && this.subscription?.capitalValue ? this.$options.filters.numberFormat(increasedShareCapital, 2) : this.$options.filters.numberFormat(this.legalEntityData?.shareCapital, 2);
          value = this.legalEntityData?.shareCapital && this.subscription?.capitalValue ? this.$options.filters.numberFormat(increasedShareCapital, 2) : this.$options.filters.numberFormat(this.legalEntityData?.shareCapital, 2);
          value = this.legalEntityData?.shareCapital && this.subscription?.capitalValue ? this.$options.filters.numberFormat(increasedShareCapital, 2) : this.$options.filters.numberFormat(this.legalEntityData?.shareCapital, 2);
          break;
        case '@Increased Share Capital in English':
          value = this.legalEntityData?.shareCapital && this.subscription?.capitalValue ? n2words(increasedShareCapital, { lang: 'en' }) : '';
          value = this.legalEntityData?.shareCapital && this.subscription?.capitalValue ? n2words(increasedShareCapital, { lang: 'en' }) : '';
          break;
        case '@Increased Share Capital in Polish':
          value = this.legalEntityData?.shareCapital && this.subscription?.capitalValue ? n2words(increasedShareCapital, { lang: 'pl' }) : '';
          value = this.legalEntityData?.shareCapital && this.subscription?.capitalValue ? n2words(increasedShareCapital, { lang: 'pl' }) : '';
          break;
        case '@Subscription Summary in English':

          if (this.product?.type === 'LLC_SHARES') {
            // 1021 (słownie: tysiąc dwadzieścia jeden) nowych równych i niepodzielnych udziałów w kapitale zakładowym Spółki o wartości nominalnej 50,00 (słownie: pięćdziesiąt) zł każdy udział
            // {instrument count} (słownie: {instrument count in polish}) new equal and indivisible shares in the share capital of the Company with a nominal value of {nominal value} (słownie: {nominal value in polish}) {product currency} każdy udział
            if (this.subscription?.llcShares) {
              value = `${this.$options.filters.thousandSeparator(this.subscription?.llcShares?.instrumentCount)} (in words: ${n2words(this.subscription?.llcShares?.instrumentCount, { lang: 'en' })}) new equal and indivisible shares in the share capital of the Company with a nominal value of ${this.$options.filters.numberFormat(this.subscription?.llcShares?.nominalValue, 2)} (in words: ${n2words(this.subscription?.llcShares?.nominalValue, { lang: 'en' })}) ${this.subscription?.currency} each share`
            }
          }

          if (this.product?.type === 'JOINT_STOCK_SHARES') {
            // serii A16 o numerach od 1 do 12354 o wartości nominalnej 1 zł (słownie: sto dwadzieścia tysięcy złotych),
            // serii A18 o numerach od 1 do 2354 o wartości nominalnej 1 zł (słownie: sto dwadzieścia tysięcy złotych)
            // serii {name} o numerach od {counter from} do {counter to} o wartości nominalnej {nominal value} (słownie: {nominal value in polish}) zł

            value = this.subscription?.seriesOfShares?.map((el: SeriesOfSharesObject) => {
              return `series ${el.name} with numbers from ${this.$options.filters.thousandSeparator(el.counterFrom)} to ${this.$options.filters.thousandSeparator(el.counterTo)} with a nominal value of ${this.$options.filters.numberFormat(el.nominalValue, 2)} (in words: ${n2words(el.nominalValue, { lang: 'en' })}) ${this.subscription?.currency}`
            })?.join(', ')
          }

          break;
        case '@Subscription Summary in Polish':

          if (this.product?.type === 'LLC_SHARES') {
            // 1021 (słownie: tysiąc dwadzieścia jeden) nowych równych i niepodzielnych udziałów w kapitale zakładowym Spółki o wartości nominalnej 50,00 (słownie: pięćdziesiąt) zł każdy udział
            // {instrument count} (słownie: {instrument count in polish}) nowych równych i niepodzielnych udziałów w kapitale zakładowym Spółki o wartości nominalnej {nominal value} (słownie: {nominal value in polish}) {product currency} każdy udział
            if (this.subscription?.llcShares) {
              value = `${this.$options.filters.thousandSeparator(this.subscription?.llcShares?.instrumentCount)} (słownie: ${n2words(this.subscription?.llcShares?.instrumentCount, { lang: 'pl' })}) nowych równych i niepodzielnych udziałów w kapitale zakładowym Spółki o wartości nominalnej ${this.$options.filters.numberFormat(this.subscription?.llcShares?.nominalValue, 2)} (słownie: ${n2words(this.subscription?.llcShares?.nominalValue, { lang: 'pl' })}) ${this.subscription?.currency} każdy udział`
            }
          } 
          
          if (this.product?.type === 'JOINT_STOCK_SHARES') {
            // serii A16 o numerach od 1 do 12354 o wartości nominalnej 1 zł (słownie: sto dwadzieścia tysięcy złotych),
            // serii A18 o numerach od 1 do 2354 o wartości nominalnej 1 zł (słownie: sto dwadzieścia tysięcy złotych)
            // serii {name} o numerach od {counter from} do {counter to} o wartości nominalnej {nominal value} (słownie: {nominal value in polish}) zł

            value = this.subscription?.seriesOfShares?.map((el: SeriesOfSharesObject) => {
              return `serii ${el.name} o numerach od ${this.$options.filters.thousandSeparator(el.counterFrom)} do ${this.$options.filters.thousandSeparator(el.counterTo)} o wartości nominalnej ${this.$options.filters.numberFormat(el.nominalValue, 2)} (słownie: ${n2words(el.nominalValue, { lang: 'pl' })}) ${this.subscription?.currency}`
            })?.join(', ')
          }

          break;
        // legal entity investor fields
        case '@LE Investor Name':
          value = this.legalEntityInvestorData?.name ? this.legalEntityInvestorData.name : '';
          break;
        case '@LE Investor Email':
          value = this.legalEntityInvestorData?.email ? this.legalEntityInvestorData.email : '';
          break;
        case '@LE Investor Tax Number':
          value = this.legalEntityInvestorData?.taxNumber ? this.legalEntityInvestorData.taxNumber : '';
          break;
        case '@LE Investor Register Number':
          value = this.legalEntityInvestorData?.registerNumber ? this.legalEntityInvestorData.registerNumber : '';
          break;
        case '@LE Investor Statistical Number':
          value = this.legalEntityInvestorData?.statisticalNumber ? this.legalEntityInvestorData.statisticalNumber : '';
          break;
        case '@LE Investor Street (registration address)':
          value = this.legalEntityInvestorData?.address?.street ? this.legalEntityInvestorData.address.street : '';
          break;
        case '@LE Investor House Number (registration address)':
          value = this.legalEntityInvestorData?.address?.houseNumber ? this.legalEntityInvestorData.address.houseNumber : '';
          break;
        case '@LE Investor Flat Number (registration address)':
          value = this.legalEntityInvestorData?.address?.flatNumber ? this.legalEntityInvestorData.address.flatNumber : '';
          break;
        case '@LE Investor City (registration address)':
          value = this.legalEntityInvestorData?.address?.city ? this.legalEntityInvestorData.address.city : '';
          break;
        case '@LE Investor Zip Code (registration address)':
          value = this.legalEntityInvestorData?.address?.zipCode ? this.legalEntityInvestorData.address.zipCode : '';
          break;
        case '@LE Investor Country (registration address)':
          value = this.legalEntityInvestorData?.address?.country ? this.legalEntityInvestorData.address.country : '';
          break;
        // other fields
        case '@Current Date':
          value = moment().utc().format('DD-MM-YYYY');
          break;
        default:
          break;
      } 
    }

    return value;
  }

  insertTag(tag: string) {
    this.editor?.commands?.insertContentAt(this.currentSelection, `<span data-type="custom-mention" data-id="${tag}"></span>`, {
      updateSelection: true,
      parseOptions: {
        preserveWhitespace: 'full',
      }
    })
  }

  insertLogo(logo: string) {
    this.editor?.commands.insertContentAt(0, logo, {
      updateSelection: true,
      parseOptions: {
        preserveWhitespace: 'full',
      }
    })
  }

  getEditorBody() {
    return this.editor.getHTML();
  }

  handleSection() {
    if(this.editor?.isActive('customSectionNode')) {
      this.editor?.chain().focus().setParagraph().run();
    } else {
      this.editor?.chain().focus().setNode('customSectionNode').run();
    }
  }

  handleTitle() {
    if (this.editor?.isActive('customTitleNode')) {
      this.editor?.chain().focus().setParagraph().run();
    } else {
      this.editor?.chain().focus().setNode('customTitleNode').run();
    }
  }

  handlePageDivider() {
    this.editor?.chain().focus().setPageDivider().run();
  }

  beforeMount() {
    const $this = this;
    const textAlignTypes = ['heading', 'paragraph'];
    this.initialContent = this.content !== undefined ? this.content : this.editorBody;

    if (this.isClean) {
      if (this.supportImages) {
        textAlignTypes.push('image');
      }
    }

    let editorExtensions = [
      StarterKit.configure({
        history: false,
      }),
      Underline,
      TextAlign.configure({
        types: textAlignTypes,
      }),
      BulletList,
      CustomListItem,
      OrderedList,
      Subscript,
      Superscript,
      CustomTitleNode,
      History.configure({
        newGroupDelay: 50,
      }),
    ];

    if (this.supportImages) {
      editorExtensions.push(Image.configure({
        allowBase64: true,
      }))
    }

    if(!this.isClean) {
      const additionalExtensions = [
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        CustomPageDivider,
        CustomParagraphNode,
        CustomMention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion
        }),
      ];
      editorExtensions = editorExtensions.concat(additionalExtensions);
    }

    this.editor = new Editor({
      editable: this.editable,
      content: this.initialContent,
      extensions: editorExtensions,
      parseOptions: {
        preserveWhitespace: true
      },
      onUpdate() {
        $this.$emit('update', $this.getEditorBody());
      },
      onBlur() {
        $this.$emit('blur', $this.getEditorBody());
      }
    })
  }

  get currentSelection() {
    return this.editor.view.state.selection.$anchor.pos;
  }

  beforeDestroy() {
    this.editor.destroy();
  }

  setEditorInitialContent(content: string) {
    this.initialContent = content;
  }

  setEditorContent(content: string) {
    this.editor?.commands?.setContent(content);
  }

  @Watch('editorBody') onEditorBodyChange() {
    this.editor?.commands?.setContent(this.editorBody);
  }

  @Watch('content') onContentChange() {
    this.editor?.commands?.setContent(this.editorBody);
  }

  @Watch('editable') onEditableUpdate() {
    this.editor?.setEditable(this.editable);
  }
}
